import React, { useEffect, useState } from "react"
import { Form, Input, Button, Select, Row, Col, InputNumber, Spin } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { LoadingOutlined } from "@ant-design/icons"

import ArrowBack from "../../../../../../assets/images/arrow-back.svg"
import { removeNewLead, updateNewLead } from "../../../../../../@common/storage/newLead"
import { liftersView as LiftersViewActions } from "../../../../../../services/LiftersView/LiftersViewActions"
import InputPhone from "../../../../../InputPhone/InputPhone"
import { navigatePage } from "../../../../../../@common/utils/navigate"
import { addSpreedSheet } from "../../../../../../@common/services/googleSheets"
import { FORMS_OPERATORS } from "../../../../../../@common/constansts/googleSheets"

export const formKeysGoogleSheets = {
  name: 'NombreEmpresa',
  email: 'CorreoCompania',
  prefix: 'Prefijo',
  phone: 'Telefono',
  prefix_country: 'PrefijoPais',
  city: 'CiudadOperacion',
  document: 'NIT',
  contact_name: 'NombreContacto',
  how_did_find_out: 'ComoTeEnteraste',
  logistics_services: 'ServicioLogistica'
}

const { Option } = Select

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
}

const FormPersonJuridical = ({ contenfulNewLead, handlerCloseModal }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formStep1] = Form.useForm()

  const [loading, setLoading] = useState()

  const { 
    error: { sendForm: hubspotSendFormError },
    success: { sendForm: hubspotSendFormSuccess }
  } = useSelector(state => state.hubspot)
  const { countries } = useSelector(state => state.poetri)
  const { newLead } = useSelector(state => state.liftersView)

  useEffect(() => {
    if(hubspotSendFormSuccess){
      removeNewLead()
      navigatePage("/lifters-thank-you")
    }
  }, [hubspotSendFormSuccess])
  
  const submitStep1 = async values => {
    setLoading(true)
    dispatch(LiftersViewActions.setNewLead({ ...newLead, ...values }))
    /*Save in google sheets*/
    const params = { ...newLead, ...values, prefix_country: newLead.prefix }
    const paramsGoogleSheets = {}
    Object.keys(params).map(key => {
      if (formKeysGoogleSheets[key])
        paramsGoogleSheets[formKeysGoogleSheets[key]] = params[key]
    })

    let indexCountry

    countries.find((country, index) => {
      if (country.alpha2code === newLead.prefix) {
        indexCountry = index
        return country
      }
    })

    const responseSheet = await addSpreedSheet(
      FORMS_OPERATORS.spreadsheetId,
      paramsGoogleSheets,
      indexCountry
    )

    sessionStorage.setItem('lifters_row', responseSheet._rowNumber)
    navigatePage("/lifters-thank-you")
    setLoading(false)
    /*Save in google sheets*/
  }

  const updateStorage = value => updateNewLead(value)

  if (!contenfulNewLead) return null

  return (
    <>
      {newLead && (
        <Form
          form={formStep1}
          {...layout}
          name="formStep1"
          onFinish={submitStep1}
          onValuesChange={updateStorage}
          initialValues={newLead || { prefix: "CO" }}
        >
          <div className="form-steps__second-step">
            <Row>
              <Col span={10}>
                <Form.Item
                  label={t("general.name_organization")}
                  name="name"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("general.email")}
                  name="email"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                    { type: "email", message: t("form.error.email") },
                  ]}
                >
                  <Input />
                </Form.Item>

                <div className="form-lifters__label__input-phone">
                  <p className="form-lifters__label">
                    {t("general.label_phone")}
                  </p>

                  <InputPhone
                    FormParent={Form}
                    formRef={formStep1}
                    prefixDetected
                    classInputGroup="form-lifters__group-phone"
                  />
                </div>

                <Form.Item
                  label={t("general.name_contact")}
                  name="contact_name"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={10} offset={2}>
                <Form.Item
                  label={t("general.city_operation")}
                  name="city"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("general.nit")}
                  name="document"
                  className="label-custom"
                  rules={[
                    { required: true, message: t("form.error.required") },
                  ]}
                >
                  <InputNumber type="number" />
                </Form.Item>
                {contenfulNewLead.how_did_you_find_out && (
                  <Form.Item
                    className="label-custom select-how-did"
                    name="how_did_find_out"
                    label={contenfulNewLead.how_did_you_find_out.label}
                    hasFeedback
                    rules={[
                      { required: true, message: t("form.error.required") },
                    ]}
                  >
                    <Select placeholder={t("general.select_options")}>
                      {contenfulNewLead.how_did_you_find_out.links.map(
                        option => (
                          <Option key={option.key} value={option.label}>
                            {option.label}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                )}
                {contenfulNewLead.logistics_services && (
                  <Form.Item
                    className="label-custom select-how-did"
                    name="logistics_services"
                    label={contenfulNewLead.logistics_services.label}
                    hasFeedback
                    rules={[
                      { required: true, message: t("form.error.required") },
                    ]}
                  >
                    <Select placeholder={t("general.select_options")}>
                      {contenfulNewLead.logistics_services.links.map(
                        option => (
                          <Option key={option.key} value={option.label}>
                            {option.label}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                )}
              </Col>

              {hubspotSendFormError && <span>{t('form.error.' + hubspotSendFormError)}</span>}

              <Form.Item
                className="form-lifters__submit button-submit-form"
                name="buttons"
              >
                <>
                  <Button
                    htmlType="submit"
                    className="btn-custom btn-custom--primary"
                  >
                    {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                    {!loading && t("button.send")}
                  </Button>
                  <Button
                    className="ravert"
                    type="button"
                    onClick={handlerCloseModal}
                  >
                    <img src={ArrowBack} alt="arrowBack" />
                    <span>{t("button.return")}</span>
                  </Button>
                </>
              </Form.Item>
            </Row>
          </div>
        </Form>
      )}
    </>
  )
}

export default FormPersonJuridical
