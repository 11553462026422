import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import "./CarouselMobile.scss"
import IconEducation from "../../../../assets/images/education.png"
import IconSecurity from "../../../../assets/images/security.png"
import IconSurprise from "../../../../assets/images/surprise.png"
import IconMoney from "../../../../assets/images/money.png"
import IconQuality from "../../../../assets/images/quality.png"

export const CaourselMobile = ({ benefits, handlerSetItemSelect }) => {

  const load = benefits?.find(item => item.key === "load")
  const security = benefits?.find(item => item.key === "security")
  const surprise = benefits?.find(item => item.key === "surprise")
  const training = benefits?.find(item => item.key === "capacity")
  const biweekly = benefits?.find(item => item.key === "biweekly")

  const listBenefits = [biweekly, load, security, surprise, training]

  const settings = {
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          infinite: true,
          centerMode: true,
          dots: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide: true,
          centerPadding: 0,
          afterChange: (index) => handlerSetItemSelect(listBenefits[index])
        },
      },
    ],
  }

  return (
    <div className="benefits__mobileCarousel" style={{ width: "95%" }}>
      <Slider {...settings}>
        <div className="items">
          <img src={IconMoney} alt="Money" />
        </div>
        <div className="items">
          <img src={IconEducation} alt="Education" />
        </div>
        <div className="items">
          <img src={IconSecurity} alt="Security" />
        </div>
        <div className="items">
          <img src={IconSurprise} alt="Surprise" />
        </div>
        <div className="items">
          <img src={IconQuality} alt="Quality" />
        </div>
      </Slider>
    </div>
  )
}
